












































import Vue from "vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { TableOptions } from "@/scripts/types";
import {
  AgreementClientOrderBy,
  ClientAgreementSearchCriteriaContainer,
  ClientAgreementSearchCriteria,
  AgreementsClientClient,
  ApiConfiguration,
  ClientAgreementContainerDto,
  UserConfigurationType,
  ActorType,
  UserLevel
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import MomentX from "@/scripts/misc/momentX";
import AgreementSearch from "@/components/web/agreements/client/AgreementSearch.vue";
import AgreementOverviewTable from "@/components/web/agreements/client/AgreementOverviewTable.vue";
import RankingDialog from "@/components/web/agreements/client/RankingDialog.vue";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    AgreementSearch,
    AgreementOverviewTable,
    RankingDialog
  },
  watch: {
    "$route.query.cargotype": {
      handler(newVal, oldVal) {
        this.cargoTypeId = Number(newVal);
        this.searchCriteria.date = new MomentX();
        if (oldVal !== undefined) {
          this.agreementContainer.agreementRows = [];
          this.search();
        }
      },
      immediate: true
    }
  },
  computed: {
    sorting(): { orderBy: AgreementClientOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as AgreementClientOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): ClientAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientAgreement,
        this.cargoTypeId
      );
    },
    atleastAdmin(): boolean {
      return [UserLevel.Admin, UserLevel.SuperAdmin].includes(this.userLevel);
    },
    userLevel(): UserLevel {
      return this.$store.getters[gets.userInfo].userLevel;
    }
  },
  methods: {
    search(tableOptionsTriggered?: boolean) {
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .bySearchCriteria(
          new ClientAgreementSearchCriteriaContainer({
            agreementSearchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.agreementContainer = res;
          this.exportDisabled =
            this.agreementContainer.agreementRows!.length === 0;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.ClientAgreement,
        subTypeId: this.cargoTypeId
      });
    },
    openRankingDialog(agreementId: number) {
      this.selectedAgreementIds = [agreementId];
      this.selectedAgreementIndex = 0;
      this.rankingDialog = true;
    },
    closeRankingDialog() {
      this.rankingDialog = false;
      this.selectedAgreementIds = [];
      this.selectedAgreementIndex = -1;
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.ClientAgreement,
          subTypeId: this.cargoTypeId,
          actorType: ActorType.Client
        })
        .then(() => {
          this.tableOptions.sortBy = [AgreementClientOrderBy.Ranking];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    exportToExcel() {
      this.exportLoading = true;
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .exportToExcel(
          new ClientAgreementSearchCriteriaContainer({
            agreementSearchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    if (this.atleastAdmin) {
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .highestRank()
        .then(res => {
          this.maxRanking = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    cargoTypeId: number;
    loading: number;
    rankingDialog: boolean;
    exportDisabled: boolean;
    exportLoading: boolean;
    selectedAgreementIds: number[];
    selectedAgreementIndex: number;
    maxRanking: number;
    tableOptions: TableOptions;
    agreementContainer: ClientAgreementContainerDto;
  } => ({
    cargoTypeId: NaN,
    loading: 0,
    rankingDialog: false,
    exportDisabled: true,
    exportLoading: false,
    selectedAgreementIds: [],
    selectedAgreementIndex: -1,
    maxRanking: 99,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [AgreementClientOrderBy.Ranking],
      sortDesc: [false],
      groupBy: ["oviktigt"]
    }),
    agreementContainer: new ClientAgreementContainerDto({
      count: 0,
      agreementRows: []
    })
  })
});
