





















































































































































































import Vue from "vue";
import { t } from "@/scripts/language/i18n";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import CreateProcurementAgreementPeriod from "@/components/web/agreements/client/CreateProcurementAgreementPeriod.vue";
import CreateProcurementAgreementRoutes from "@/components/web/agreements/client/CreateProcurementAgreementRoutes.vue";
import CreateProcurementProcurementPeriods from "@/components/web/agreements/client/CreateProcurementProcurementPeriods.vue";
import CreateProcurementSuppliers from "@/components/web/agreements/client/CreateProcurementSuppliers.vue";
import CreateProcurementReview from "@/components/web/agreements/client/CreateProcurementReview.vue";
import CreateProcurementAgreementRoutesDialog from "@/components/web/agreements/client/CreateProcurementAgreementRoutesDialog.vue";
import {
  ActorType,
  AgreementPeriodDto,
  AgreementRouteForProcurementDto,
  AgreementRoutesClient,
  AgreementsClientClient,
  AgreementToDateDto,
  ApiConfiguration,
  CargoType,
  DocType,
  DocumentGroupDto,
  DocumentsClient,
  ProcurementPeriodDto,
  ProcurementsClientClient,
  QualityPointsClientClient,
  QualityPointsPastYearDto,
  RepositoryClient,
  RepositoryDto,
  SaveProcurementDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { UserConfigurationEntry } from "@/scripts/store/userConfModule";
import { alertDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { pushNotification } from "@/scripts/misc/notifications";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    SearchCard,
    CreateProcurementAgreementPeriod,
    CreateProcurementAgreementRoutes,
    CreateProcurementProcurementPeriods,
    CreateProcurementAgreementRoutesDialog,
    CreateProcurementSuppliers,
    CreateProcurementReview
  },
  watch: {
    valuesAffectingDbFetch: {
      handler() {
        if (this.procurement.agreementPeriod!.from) {
          this.fetchQualityPoints();
          this.fetchAgreementRoutes();
          this.fetchSuppliers();
          this.fetchDocumentGroups();
          this.validateLists();
        }
      },
      immediate: true,
      deep: true
    },
    procurementStart: {
      handler() {
        this.fetchBaseIndexName();
      },
      immediate: true
    },
    "procurement.cargoType": {
      handler() {
        this.fetchAgreementToDates();
      },
      immediate: true
    }
  },
  computed: {
    valuesAffectingDbFetch(): any[] {
      return [
        this.procurement.cargoType,
        this.procurement.agreementPeriod!.from
      ];
    },
    procurementStart(): MomentX | undefined {
      if (!this.procurement.procurementPeriods!.length) {
        return undefined;
      }
      return this.procurement.procurementPeriods![0].from;
    },
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 278);
    },
    nextEnabled(): boolean {
      return this.steps.every(s => s.id > this.currentStep || s.completed);
    },
    draft(): SaveProcurementDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ProcurementDraft,
        1
      );
    },
    routesForDialog(): AgreementRouteForProcurementDto[] {
      return this.agreementRoutes.filter(r =>
        this.procurement.agreementRouteIds!.includes(r.id)
      );
    }
  },
  methods: {
    loadDraft() {
      this.procurement = this.draft;
      this.isWorkingWithDraft = true;
    },
    saveDraft() {
      this.saveDraftInProgress = true;
      this.isWorkingWithDraft = true;
      const entry: UserConfigurationEntry = {
        type: UserConfigurationType.ProcurementDraft,
        subTypeId: 1,
        conf: this.procurement
      };
      this.$store.commit(mutations.setUserConf, entry);
      this.storeDraft();
    },
    removeDraft() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.ProcurementDraft,
          subTypeId: 1,
          actorType: ActorType.Client
        })
        .then(() => {
          this.storeDraft();
        });
    },
    storeDraft() {
      this.$store
        .dispatch(actions.saveUserConf, {
          type: UserConfigurationType.ProcurementDraft,
          subTypeId: 1
        })
        .then(() => {
          this.saveDraftInProgress = false;
        });
    },
    goToProcurements() {
      this.$router.push(
        "/procurements?cargotype=" + this.procurement.cargoType
      );
    },
    createProcurement() {
      popupDialog({
        title: t("CreateProcurement"),
        body: t("CreateProcurementConfirmation"),
        btnText1: t("CreateProcurement"),
        btnColor1: "success",
        btnCallback1: () => {
          this.actuallyCreateProcurement();
        },
        btnText2: t("Cancel"),
        btnColor2: "warning",
        btnCallback2: () => {}
      });
    },
    actuallyCreateProcurement() {
      this.loadingSemaphore++;
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .createProcurement(this.procurement)
        .then(() => {
          this.procurementCreated = true;
          pushNotification(t("Created") + "!", "mdi-text-box");
          if (this.isWorkingWithDraft) {
            this.removeDraft();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    resetPage() {
      window.location.href = "/create-procurement";
    },
    completed(step: number) {
      return this.steps[step - 1].completed && this.currentStep > step;
    },
    color(step: number): string {
      return this.completed(step) ? "success" : "cyan darken-1";
    },
    change(step: number, completed: boolean, info: string | undefined) {
      this.steps[step - 1].completed = completed;
      this.steps[step - 1].info = info;
    },
    fetchAgreementRoutes() {
      this.loadingSemaphore++;
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .listForProcurement(
          this.procurement.cargoType,
          this.procurement.agreementPeriod!.from
        )
        .then(res => {
          this.agreementRoutes = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuppliers() {
      this.loadingSemaphore++;
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .suppliersForProcurement(this.procurement.cargoType)
        .then(res => {
          this.suppliers = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchAgreementToDates() {
      if (!this.procurement.cargoType) {
        return;
      }
      this.loadingSemaphore++;
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .agreementToDates(this.procurement.cargoType)
        .then(res => {
          this.agreementToDates = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchQualityPoints() {
      this.loadingSemaphore++;
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .qualityPointsPastYear(this.procurement.cargoType)
        .then(res => {
          this.qualityPoints = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocumentGroups() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .list(DocType.TermsOfAgreement, false)
        .then(res => {
          this.documentGroups = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchBaseIndexName() {
      if (
        !this.procurementStart ||
        this.procurement.cargoType !== CargoType.Road
      ) {
        this.baseIndexText = undefined;
        return;
      }
      new RepositoryClient(new ApiConfiguration(this.$store))
        .baseIndexPeriodByValidDate(this.procurementStart)
        .then(res => {
          this.baseIndexText = t("IndexUsedAsBaseindex").replace(
            "{0}",
            res.displayName!
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    validateLists() {
      if (this.procurement.supplierIds!.length > 0) {
        new ProcurementsClientClient(new ApiConfiguration(this.$store))
          .validateSuppliers(
            this.procurement.cargoType,
            this.procurement.supplierIds!
          )
          .then(res => {
            this.procurement.supplierIds = res
              .filter(r => r.valid)
              .map(r => r.supplierId);
            const invalid = res.filter(r => !r.valid);
            if (invalid.length === 0) {
              return;
            }
            alertDialog(
              t("Suppliers"),
              t("SuppliersCantBeIncluded") +
                ": \n\n" +
                invalid.map(r => r.name).join("\n")
            );
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
      if (this.procurement.agreementRouteIds!.length > 0) {
        new ProcurementsClientClient(new ApiConfiguration(this.$store))
          .validateAgreementroutes(
            this.procurement.cargoType,
            this.procurement.agreementPeriod!.from,
            this.procurement.agreementRouteIds!
          )
          .then(res => {
            this.procurement.agreementRouteIds = res
              .filter(r => r.valid)
              .map(r => r.agreementRouteId);
            const invalid = res
              .filter(r => !r.valid)
              .sort((a, b) =>
                stringCompare(a.reason! + a.name, b.reason! + b.name)
              );
            if (invalid.length === 0) {
              return;
            }
            alertDialog(
              t("AgreementRoutes"),
              t("AgreementRoutesCantBeIncluded") +
                ": \n\n" +
                invalid.map(r => r.reason + " : " + r.name).join("\n"),
              800
            );
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    }
  },
  data: (): {
    procurementCreated: boolean;
    loadingSemaphore: number;
    saveDraftInProgress: boolean;
    isWorkingWithDraft: boolean;
    currentStep: number;
    routesDialog: boolean;
    baseIndexText: string | undefined;
    agreementToDates: AgreementToDateDto[];
    agreementRoutes: AgreementRouteForProcurementDto[];
    suppliers: RepositoryDto[];
    qualityPoints: QualityPointsPastYearDto[];
    documentGroups: DocumentGroupDto[];
    steps: {
      id: number;
      name: string;
      info: string | undefined;
      completed: boolean;
    }[];
    procurement: SaveProcurementDto;
  } => ({
    procurementCreated: false,
    loadingSemaphore: 0,
    saveDraftInProgress: false,
    isWorkingWithDraft: false,
    currentStep: 1,
    routesDialog: false,
    baseIndexText: undefined,
    agreementToDates: [],
    agreementRoutes: [],
    suppliers: [],
    qualityPoints: [],
    documentGroups: [],
    steps: [
      { id: 1, name: t("AgreementPeriod"), info: undefined, completed: false },
      { id: 2, name: t("AgreementRoutes"), info: undefined, completed: false },
      {
        id: 3,
        name: t("ProcurementPeriods"),
        info: undefined,
        completed: false
      },
      { id: 4, name: t("Suppliers"), info: undefined, completed: false },
      {
        id: 5,
        name: t("Finish"),
        info: undefined,
        completed: false
      }
    ],
    procurement: new SaveProcurementDto({
      cargoType: undefined as any,
      message: "",
      documentGroupId: undefined as any,
      agreementRouteIds: [],
      supplierIds: [],
      agreementPeriod: new AgreementPeriodDto({
        from: undefined as any,
        to: undefined as any
      }),
      procurementPeriods: [
        new ProcurementPeriodDto({
          number: 1,
          from: undefined as any,
          to: undefined as any,
          rankingMethod: undefined as any,
          qualityPointsFrom: undefined as any,
          qualityPointsTo: undefined as any
        })
      ]
    })
  })
});
