






























import Vue from "vue";
import {
  AgreementRouteWithAgreementStatusDto,
  LoadClientEditSubLocations,
  OrderType,
  SubLocationDto
} from "@/scripts/cld.api";
import { saveBeforeContinueDialog } from "@/scripts/misc/popupDialogs";
import objectsEqual from "@/scripts/misc/objectsEqual";
import MomentX from "@/scripts/misc/momentX";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import AccordionSubLocationsTable from "@/components/web/loads/client/accordion/AccordionSubLocationsTable.vue";
import cached from "@/scripts/misc/cached";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DialogCard,
    AccordionSubLocationsTable
  },
  props: {
    dialog: Boolean,
    agreementRouteId: Number,
    orderType: Number as () => OrderType,
    agreementDate: MomentX,
    subLocations: Object as () => LoadClientEditSubLocations
  },
  watch: {
    agreementDate: {
      handler() {
        this.fetchAgreementRoutes();
      },
      immediate: true
    },
    dialog: {
      handler() {
        if (this.dialog) {
          this.pickupDraft = [
            ...this.subLocations.subPickupLocations!.map(x => this.copy(x))
          ];
          this.deliveryDraft = [
            ...this.subLocations.subDeliveryLocations!.map(x => this.copy(x))
          ];
          this.addEmptyRowIfNeeded(true);
          this.addEmptyRowIfNeeded(false);
        }
      },
      immediate: true
    },
    agreementRoute() {
      this.addEmptyRowIfNeeded(true);
      this.addEmptyRowIfNeeded(false);
    }
  },
  computed: {
    pendingChanges(): boolean {
      return (
        !objectsEqual(
          this.subLocations.subPickupLocations,
          this.pickupDraft.slice(0, -1)
        ) ||
        !objectsEqual(
          this.subLocations.subDeliveryLocations,
          this.deliveryDraft.slice(0, -1)
        )
      );
    },
    agreementRoute(): AgreementRouteWithAgreementStatusDto | undefined {
      return this.agreementRoutes.find(ar => ar.id === this.agreementRouteId);
    }
  },
  methods: {
    close() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.saveDraft();
          },
          () => {
            this.closeCallback();
          }
        );
      } else {
        this.closeCallback();
      }
    },
    closeCallback() {
      this.$emit("close");
    },
    saveDraft() {
      this.subLocations.subPickupLocations = this.pickupDraft
        .slice(0, -1)
        .map(x => this.copy(x));
      this.subLocations.subDeliveryLocations = this.deliveryDraft
        .slice(0, -1)
        .map(x => this.copy(x));
      this.closeCallback();
    },
    locationsDraft(pickup: boolean): SubLocationDto[] {
      return pickup ? this.pickupDraft : this.deliveryDraft;
    },
    addEmptyRowIfNeeded(pickup: boolean) {
      if (!this.agreementRoute) {
        return;
      }
      if (this.locationsDraft(pickup).filter(l => !l.locationId).length === 0) {
        this.locationsDraft(pickup).push(
          new SubLocationDto({
            locationId: 0,
            country: pickup
              ? this.agreementRoute.pickupCountry
              : this.agreementRoute.deliveryCountry,
            address: undefined,
            locationDisplayNameReadOnly: undefined
          })
        );
      }
    },
    copy(location: SubLocationDto): SubLocationDto {
      return new SubLocationDto({
        locationId: location.locationId,
        country: location.country,
        address: location.address,
        locationDisplayNameReadOnly: undefined
      });
    },
    remove(index: number, pickup: boolean) {
      this.locationsDraft(pickup).splice(index, 1);
    },
    fetchAgreementRoutes() {
      cached("agreementRoutes", this.orderType, this.agreementDate)
        .then(res => {
          this.agreementRoutes = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    pickupDraft: SubLocationDto[];
    deliveryDraft: SubLocationDto[];
    agreementRoutes: AgreementRouteWithAgreementStatusDto[];
  } => ({
    pickupDraft: [],
    deliveryDraft: [],
    agreementRoutes: []
  })
});
