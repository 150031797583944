




































































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { RepositoryDto, UserDto } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { validEmail } from "@/scripts/misc/stringUtils";
import { cultures, userLevels } from "@/scripts/misc/enumLists";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  props: {
    user: UserDto
  },
  components: {
    TextSingle,
    TextMulti,
    Dropdown,
    ReadOnlyText
  },
  methods: {
    validEmail: validEmail,
    userLevels: userLevels,
    cultures: cultures,
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchCountries();
  },
  data: (): {
    countries: RepositoryDto[];
  } => ({
    countries: []
  })
});
