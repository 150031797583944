













































import Vue from "vue";
import { LoadClientEditContainersDto, OrderType } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    terminalName: String,
    blNo: String,
    containerValues: LoadClientEditContainersDto,
    orderType: Number as () => OrderType
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return this.orderType === OrderType.ClassicContainer;
    }
  }
});
