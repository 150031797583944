








































































import Vue from "vue";
import {
  ApiConfiguration,
  SubjectiveQPWeightsDto,
  PerformanceQPWeightsDto,
  RepositoryClient,
  RepositoryDto,
  SupportAdminQualityPointsClient,
  ActorsClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import QualityPointsWeightsTable from "@/components/web/qp/QualityPointsWeightsTable.vue";
import { cargoTypes } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    Dropdown,
    QualityPointsWeightsTable
  },
  watch: {
    "$route.query": {
      handler() {
        this.fetchPerformanceWeights();
        this.fetchSubjectiveWeights();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    clientId: {
      get(): number {
        return Number(this.$route.query.clientid);
      },
      set(clientId: number) {
        this.$router.push({
          path: this.$route.path,
          query: {
            clientid: clientId.toString(),
            cargotype: this.cargoType.toString()
          }
        });
      }
    },
    cargoType: {
      get(): number {
        return Number(this.$route.query.cargotype);
      },
      set(cargoType: number) {
        this.$router.push({
          path: this.$route.path,
          query: {
            clientid: this.clientId.toString(),
            cargotype: cargoType.toString()
          }
        });
      }
    }
  },
  methods: {
    cargoTypes: cargoTypes,
    fetchPerformanceWeights() {
      if (!this.cargoType || !this.clientId) {
        return;
      }
      this.loadingSemaphore++;
      new SupportAdminQualityPointsClient(new ApiConfiguration(this.$store))
        .performanceWeights(this.cargoType, this.clientId)
        .then(res => {
          this.performanceWeights = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSubjectiveWeights() {
      if (!this.clientId) {
        return;
      }
      this.loadingSemaphore++;
      new SupportAdminQualityPointsClient(new ApiConfiguration(this.$store))
        .subjectiveWeights(this.clientId)
        .then(res => {
          this.subjectiveWeights = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    new ActorsClient(new ApiConfiguration(this.$store))
      .clientsForSupportAdmin(true)
      .then(res => {
        this.clients = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    clients: RepositoryDto[];
    loadingSemaphore: number;
    performanceWeights: PerformanceQPWeightsDto[];
    subjectiveWeights: SubjectiveQPWeightsDto[];
  } => ({
    clients: [],
    loadingSemaphore: 0,
    performanceWeights: [],
    subjectiveWeights: []
  })
});
