var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiny-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.standardPhrases,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"options":_vm.tableOptions,"hide-default-header":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('click', item.id)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.numberOfRoutes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNumberOfConnectedRoutes(item))+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.subject))])]}},{key:"item.body",fn:function(ref){
var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.body,"width":"400"}})]}},{key:"item.trash-icon",fn:function(ref){
var item = ref.item;
return [(_vm.canBeDeleted(item))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('delete', item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-trash-can-outline ")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }