

























import Vue from "vue";
import { LoadClientEditAssortedValuesDto, OrderType } from "@/scripts/cld.api";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    step: Number,
    assortedValues: LoadClientEditAssortedValuesDto,
    orderType: Number as () => OrderType
  },
  components: {
    NumberInput,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return this.orderType === OrderType.ClassicMachineTransport;
    }
  }
});
