













import Vue from "vue";
import { InvoiceRowSupportAdminDto } from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    row: InvoiceRowSupportAdminDto
  },
  computed: {
    sql(): string {
      if (!this.row) {
        return "";
      }
      return `select l.id as loadid, clientid, cargotypeid, statusid, loadeddate, unit, p.Distance from loads l
join agreementroutes ar on ar.id = l.RouteID
join pricerows p on p.id = l.PriceRowID
where l.id in (${this.row.loadIds!.join(",")})`;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
});
