






















































import Vue from "vue";
import {
  ApiConfiguration,
  BackgroundTaskClient,
  BackgroundTaskDto,
  BackgroundTaskStatusDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { stringCompare } from "@/scripts/misc/stringUtils";
import BackgroundTasksTable from "@/components/support-admin/BackgroundTasksTable.vue";

export default Vue.extend({
  components: {
    SearchCard,
    BackgroundTasksTable
  },
  computed: {
    frequentJobs(): BackgroundTaskStatusDto[] {
      return this.jobs
        .filter(job => job.repeatInterval !== 0 && job.repeatInterval !== 86400)
        .sort(
          (a, b) =>
            stringCompare(a.repeatInterval, b.repeatInterval) ||
            a.taskId - b.taskId
        );
    },
    nightJobs(): BackgroundTaskStatusDto[] {
      return this.jobs
        .filter(job => job.repeatInterval !== 0 && job.repeatInterval === 86400)
        .sort((a, b) => a.startAt!.unix() - b.startAt!.unix());
    }
  },
  methods: {
    fetchJobs() {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.jobs = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    updateJob(taskId: number, enabled: boolean) {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .update(
          new BackgroundTaskDto({
            taskId: taskId,
            enabled: enabled
          })
        )
        .then(() => {
          this.loading = false;
          this.fetchJobs();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    runJob(taskId: number) {
      this.loading = true;
      new BackgroundTaskClient(new ApiConfiguration(this.$store))
        .run(taskId)
        .then(() => {
          this.loading = false;
          this.fetchJobs();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    startSafe() {
      const unsafe = [8, 9, 13, 16, 17];
      this.jobs
        .filter(job => !unsafe.includes(job.taskId))
        .filter(job => job.repeatInterval !== 0)
        .map(job => this.updateJob(job.taskId, true));
    },
    stopAll() {
      this.updateJob(0, false);
    }
  },
  mounted() {
    this.fetchJobs();
  },
  data: (): {
    loading: boolean;
    jobs: BackgroundTaskStatusDto[];
  } => ({
    loading: false,
    jobs: []
  })
});
