var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogCard',{attrs:{"dialog":_vm.dialog,"title":_vm.title,"loading":_vm.loading,"width":1400},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [[_c('v-switch',{attrs:{"label":_vm.$t('ShowInactivated'),"hide-details":""},model:{value:(_vm.showInactivated),callback:function ($$v) {_vm.showInactivated=$$v},expression:"showInactivated"}})],_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('UsersTable',{attrs:{"users":_vm.filteredUsers,"selectedLoadUserId":_vm.selectedLoadUserId},on:{"selectUser":function (id) { return (_vm.selectedLoadUserId = id); }}})],1),(_vm.user)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{ref:"userForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('UserForm',{attrs:{"user":_vm.user}})],1)],1):_vm._e()],1)]},proxy:true},{key:"actions",fn:function(){return [(_vm.user)?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.clearConfigInProgress,"disabled":_vm.enableInProgress ||
              _vm.unlockInProgress ||
              _vm.resetCredentialsInProgress ||
              _vm.saveInProgress,"color":"error","depressed":""},on:{"click":_vm.clearConfigOnUser}},[_vm._v(" Rensa inställningar ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","disabled":_vm.actor.active},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"loading":_vm.enableInProgress,"disabled":_vm.clearConfigInProgress ||
                    _vm.unlockInProgress ||
                    _vm.resetCredentialsInProgress ||
                    _vm.saveInProgress ||
                    !_vm.actor.active,"color":"warning","depressed":""},on:{"click":_vm.toggleEnabled}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-account-cancel-outline")]),_vm._v(" "+_vm._s(_vm.user && _vm.user.isEnabled ? "Inaktivera användare" : "Aktivera användare")+" ")],1)],1)]}}],null,false,3105269349)},[_vm._v(" Aktören är inaktiverad ")])],1),(_vm.user.lockoutEnd)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.unlockInProgress,"disabled":_vm.clearConfigInProgress ||
              _vm.enableInProgress ||
              _vm.resetCredentialsInProgress ||
              _vm.saveInProgress,"color":"secondary","depressed":""},on:{"click":_vm.unlockUser}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-lock-open-variant-outline")]),_vm._v(" Lås upp ")],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.resetCredentialsInProgress,"disabled":_vm.clearConfigInProgress ||
              _vm.enableInProgress ||
              _vm.unlockInProgress ||
              _vm.saveInProgress,"color":"purple lighten-3","depressed":""},on:{"click":_vm.getResetCredentialsLink}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-link-lock")]),_vm._v(" Återställningslänk ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.clearConfigInProgress ||
              _vm.enableInProgress ||
              _vm.unlockInProgress ||
              _vm.resetCredentialsInProgress ||
              _vm.saveInProgress,"color":"btn-cyan","depressed":""},on:{"click":function($event){_vm.notificationDialog = true}}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-email-check-outline")]),_vm._v(" Notifieringar ")],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.saveInProgress,"disabled":_vm.clearConfigInProgress ||
              _vm.enableInProgress ||
              _vm.unlockInProgress ||
              _vm.resetCredentialsInProgress,"color":"success","depressed":""},on:{"click":_vm.saveUser}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-floppy")]),_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")],1)],1)],1):_vm._e()]},proxy:true},{key:"dialogs",fn:function(){return [(_vm.user)?_c('UserNotificationSettingsDialog',{attrs:{"dialog":_vm.notificationDialog,"loadUserId":_vm.user.loadUserId},on:{"close":function($event){_vm.notificationDialog = false}}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }