



























import Vue from "vue";
import {
  AgreementSupplierOrderBy,
  AgreementsSupplierClient,
  ApiConfiguration,
  SupplierAgreementContainerDto,
  SupplierAgreementSearchCriteria,
  SupplierAgreementSearchCriteriaContainer,
  UserConfigurationType,
  ActorType
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { TableOptions } from "@/scripts/types";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import MomentX from "@/scripts/misc/momentX";
import AgreementSearch from "@/components/web/agreements/supplier/AgreementSearch.vue";
import AgreementTable from "@/components/web/agreements/supplier/AgreementTable.vue";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    AgreementSearch,
    AgreementTable
  },
  watch: {
    "$route.query.cargotype": {
      handler(newVal, oldVal) {
        this.cargoType = Number(newVal);
        this.searchCriteria.date = new MomentX();
        if (oldVal !== undefined) {
          this.agreementContainer.agreementRows = [];
          this.search(true, false);
        }
      },
      immediate: true
    },
    "searchCriteria.invoicePrice"() {
      this.search(false, true);
    }
  },
  computed: {
    searchCriteria(): SupplierAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierAgreement,
        this.cargoType
      );
    },
    sorting(): { orderBy: AgreementSupplierOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as AgreementSupplierOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    }
  },
  methods: {
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(false, false);
    },
    search(goToFirstPage: boolean, saveUserConf: boolean) {
      if (!this.searchCriteria.date) {
        return;
      }
      if (goToFirstPage) {
        this.tableOptions.page = 1;
        scrollTableToTop(this);
      }
      const unique = nextUnique();
      this.loading = unique;
      new AgreementsSupplierClient(new ApiConfiguration(this.$store))
        .bySearchCriteria(
          new SupplierAgreementSearchCriteriaContainer({
            agreementSearchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.agreementContainer = res;
          this.exportDisabled =
            this.agreementContainer.agreementRows!.length === 0;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      if (saveUserConf) {
        this.$store.dispatch(actions.saveUserConf, {
          type: UserConfigurationType.SupplierAgreement,
          subTypeId: this.cargoType
        });
      }
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.SupplierAgreement,
          subTypeId: this.cargoType,
          actorType: ActorType.Supplier
        })
        .then(() => {
          this.tableOptions.sortBy = [AgreementSupplierOrderBy.PickupLocation];
          this.tableOptions.sortDesc = [false];
          this.search(true, true);
        });
    },
    exportToExcel() {
      this.exportLoading = true;
      new AgreementsSupplierClient(new ApiConfiguration(this.$store))
        .exportToExcel(
          new SupplierAgreementSearchCriteriaContainer({
            agreementSearchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    cargoType: number;
    exportDisabled: boolean;
    exportLoading: boolean;
    agreementContainer: SupplierAgreementContainerDto;
    tableOptions: TableOptions;
  } => ({
    loading: 0,
    cargoType: NaN,
    exportDisabled: true,
    exportLoading: false,
    agreementContainer: new SupplierAgreementContainerDto({
      count: 0,
      agreementRows: []
    }),
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [AgreementSupplierOrderBy.PickupLocation],
      sortDesc: [false],
      groupBy: ["oviktigt"]
    })
  })
});
