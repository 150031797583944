



































































































































































import Vue from "vue";
import {
  AgreementRouteWithAgreementStatusDto,
  Country,
  CountyWithZipcodeDto,
  LoadClientEditAssortedValuesDto,
  LoadClientEditInfoTextsDto,
  LoadClientEditLocationDto,
  LoadClientPublishValues,
  LoadStatus,
  OrderType,
  RepositoryDto,
  TruckTypeDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import cached from "@/scripts/misc/cached";
import PublishSpeedSlider from "@/components/shared/input/PublishSpeedSlider.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import AddressCombobox from "@/components/shared/input/AddressCombobox.vue";
import TextSaved from "@/components/shared/input/TextSaved.vue";
import DistanceInput from "@/components/shared/input/DistanceInput.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import Emission from "@/components/shared/ui/Emission.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    step: Number,
    publishValues: LoadClientPublishValues,
    location: LoadClientEditLocationDto,
    infoTexts: LoadClientEditInfoTextsDto,
    assortedValues: LoadClientEditAssortedValuesDto,
    orderType: Number as () => OrderType,
    agreementRoute: AgreementRouteWithAgreementStatusDto,
    agreementRoutes: Array as () => AgreementRouteWithAgreementStatusDto[],
    agreementDate: MomentX,
    loadStatus: Number as () => LoadStatus | undefined
  },
  components: {
    PublishSpeedSlider,
    Autocomplete,
    AddressCombobox,
    TextSaved,
    DistanceInput,
    Dropdown,
    Emission,
    GreyTitle
  },
  watch: {
    agreementDate() {
      this.$emit("fetchAgreementRoutes");
      this.fetchTruckTypes();
    },
    agreementRoute(
      newVal?: AgreementRouteWithAgreementStatusDto,
      oldVal?: AgreementRouteWithAgreementStatusDto
    ) {
      this.fetchTruckTypes();
      this.fetchLocations();
      if (!newVal || !oldVal || newVal.id !== oldVal.id) {
        if (this.loadStatus === undefined) {
          this.publishValues.truckType = undefined as any;
          this.location.pickupStreetAddress = newVal
            ? newVal.predefinedPickupAddress
            : "";
          this.location.deliveryStreetAddress = "";
          this.autoSelectLocations();
        }
      }

      if (this.loadStatus === undefined && newVal?.id !== oldVal?.id) {
        this.infoTexts.pickupInfo = this.agreementRoute?.predefinedPickupInfo;
        this.infoTexts.destinationInfo = this.agreementRoute?.predefinedDeliveryInfo;
      }
    }
  },
  computed: {
    pickupLocation(): { country?: Country; county?: string; zipcode?: string } {
      let res: { country?: Country; county?: string; zipcode?: string } = {};
      if (this.agreementRoute) {
        res.country = this.agreementRoute.pickupCountry;
      }
      const location = this.pickupLocations.find(
        l => l.id === this.location.pickupLocationId
      );
      if (!!location) {
        res.county = location.county;
        res.zipcode = location.zipcode;
      }
      return res;
    },
    deliveryLocation(): {
      country?: Country;
      county?: string;
      zipcode?: string;
    } {
      let res: { country?: Country; county?: string; zipcode?: string } = {};
      if (this.agreementRoute) {
        res.country = this.agreementRoute.deliveryCountry;
      }
      const location = this.deliveryLocations.find(
        l => l.id === this.location.deliveryLocationId
      );
      if (!!location) {
        res.county = location.county;
        res.zipcode = location.zipcode;
      }
      return res;
    }
  },
  methods: {
    autoSelectLocations() {
      if (!this.agreementRoute) {
        return;
      }
      this.location.pickupLocationId = this.agreementRoute.pickupLocationId;
      this.location.deliveryLocationId = this.agreementRoute.deliveryLocationId;
    },
    changeZipcode(zipcode: string, pickup: boolean) {
      const locations = pickup ? this.pickupLocations : this.deliveryLocations;
      const currentLocationId = pickup
        ? this.location.pickupLocationId
        : this.location.deliveryLocationId;
      const currentLocation = locations.find(l => l.id === currentLocationId);
      const newLocation = locations.find(l => l.zipcode === zipcode);
      if (
        newLocation &&
        (!currentLocation || newLocation.zipcode !== currentLocation.zipcode)
      ) {
        if (pickup) {
          this.location.pickupLocationId = newLocation.id;
        } else {
          this.location.deliveryLocationId = newLocation.id;
        }
      }
    },
    fetchTruckTypes() {
      if (!this.publishValues.agreementRouteId) {
        return;
      }
      cached(
        "trucktypesByAgreementrouteid",
        this.orderType as any,
        this.agreementDate,
        this.publishValues.agreementRouteId
      )
        .then(res => {
          this.truckTypes = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLocations() {
      if (!this.agreementRoute) {
        return;
      }
      cached("countiesAndZipcode", this.agreementRoute.pickupCountry)
        .then(res => {
          this.pickupLocations = res;
          this.pickupLocationsConcated = res.map(
            x =>
              new RepositoryDto({
                id: x.id,
                text: `${x.county}, ${x.zipcode}`
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      cached("countiesAndZipcode", this.agreementRoute.deliveryCountry)
        .then(res => {
          this.deliveryLocations = res;
          this.deliveryLocationsConcated = res.map(
            x =>
              new RepositoryDto({
                id: x.id,
                text: `${x.county}, ${x.zipcode}`
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    setPickupInfoModified() {
      this.pickupInfoPristine = false;
    },
    setDestinationInfoModified() {
      this.destinationInfoPristine = false;
    }
  },
  mounted() {
    this.$emit("fetchAgreementRoutes");
  },
  data: (): {
    truckTypes: TruckTypeDto[];
    pickupLocations: CountyWithZipcodeDto[];
    pickupLocationsConcated: RepositoryDto[];
    deliveryLocations: CountyWithZipcodeDto[];
    deliveryLocationsConcated: RepositoryDto[];
    pickupInfoPristine: boolean;
    destinationInfoPristine: boolean;
  } => ({
    truckTypes: [],
    pickupLocations: [],
    pickupLocationsConcated: [],
    deliveryLocations: [],
    deliveryLocationsConcated: [],
    pickupInfoPristine: true,
    destinationInfoPristine: true
  })
});
