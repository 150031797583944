
















































































































































import Vue from "vue";
import Language from "@/components/shared/ui/Language.vue";
import { menu } from "@/scripts/misc/menuLayout";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";
import Config from "@/scripts/config/config";

export default Vue.extend({
  props: {
    menu: Array as () => menu[],
    title: String,
    actorName: String,
    username: String,
    home: String,
    unauthenticatedPage: Boolean
  },
  components: {
    Language
  },
  computed: {
    screenWidth(): number {
      return this.$store.getters[gets.dimensions].width;
    },
    narrowButtons(): boolean {
      return this.screenWidth < 1300;
    },
    hideArrows(): boolean {
      return this.screenWidth < 1130;
    },
    smallerButtons(): boolean {
      return this.screenWidth < 1030;
    },
    allowedToMutateUser(): boolean {
      return this.$store.getters[gets.userInfo].supportAdminLevel >= 2;
    },
    shouldWarnAboutMutation(): boolean {
      return (
        Config.getTitle() === "C-Load" &&
        this.allowedToMutateUser &&
        this.$store.getters[gets.userInfo].actorId !== 48
      );
    },
    unviewedMessages(): number | undefined {
      return this.$store.getters[gets.unviewedMessages];
    }
  },
  methods: {
    text(item: menu): string | undefined {
      if (!item.text || item.untranslated) {
        return item.text;
      }
      return t(item.text);
    },
    signout() {
      this.$emit("signout");
    },
    mutateUser() {
      this.routeTo("/user-mutation");
    },
    mutateTriona() {
      this.routeTo("/user-mutation?actorid=48");
    },
    routeTo(path: string | undefined) {
      if (path && path.startsWith("https")) {
        window.open(path, "_blank");
      } else {
        this.$emit("routeTo", path);
      }
    },
    showArrow(item: menu): boolean {
      return !!item.items && item.items.length > 0 && !this.hideArrows;
    }
  }
});
