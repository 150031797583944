


























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  DistanceReportOrderBy,
  ReportsClient,
  DistanceReportSearchCriteriaContainer,
  DistanceReportSearchCriteria,
  DistanceReportContainerDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import DistanceReportSearch from "@/components/web/reports/shared/DistanceReportSearch.vue";
import DistanceReportTable from "@/components/web/reports/shared/DistanceReportTable.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    DistanceReportSearch,
    DistanceReportTable
  },
  computed: {
    sorting(): { orderBy: DistanceReportOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as DistanceReportOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): DistanceReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DistanceReport,
        1
      );
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.DistanceReport,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [DistanceReportOrderBy.PickupLocation];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.fromDate || !this.searchCriteria.toDate) {
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new ReportsClient(new ApiConfiguration(this.$store))
        .distance(
          new DistanceReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.DistanceReport,
        subTypeId: 1
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .distanceExportToExcel(
          new DistanceReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    exportLoading: boolean;
    tableOptions: TableOptions;
    reportContainer: DistanceReportContainerDto;
  } => ({
    loading: 0,
    exportLoading: false,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [DistanceReportOrderBy.PickupLocation],
      sortDesc: [false]
    }),
    reportContainer: new DistanceReportContainerDto({
      count: 0,
      rows: [],
      numLoads: 0,
      numLoadsWithDistance: 0,
      totalDistance: 0,
      avgDistance: undefined,
      totalEmission: 0,
      avgEmission: undefined,
      numLoadsFT: 0,
      distanceFT: 0
    })
  })
});
