






























































import Vue from "vue";
import { LoadClientEditSubLocations, OrderType } from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import AccordionSubLocationsDialog from "@/components/web/loads/client/accordion/AccordionSubLocationsDialog.vue";
import AccordionSubLocationsTable from "@/components/web/loads/client/accordion/AccordionSubLocationsTable.vue";

export default Vue.extend({
  components: {
    AccordionSubLocationsDialog,
    AccordionSubLocationsTable
  },
  props: {
    agreementRouteId: Number,
    orderType: Number as () => OrderType,
    agreementDate: MomentX,
    subLocations: Object as () => LoadClientEditSubLocations | undefined,
    expanded: Boolean
  },
  computed: {
    disabled(): boolean {
      return !this.subLocations;
    },
    numLocations(): string {
      if (!this.subLocations) {
        return "";
      }
      return `(${this.subLocations.subPickupLocations!.length +
        this.subLocations.subDeliveryLocations!.length})`;
    }
  },
  methods: {
    editClicked(e: any) {
      this.dialog = true;
      if (this.expanded) {
        e.stopPropagation();
      }
    }
  },
  data: (): { dialog: boolean } => ({ dialog: false })
});
