






























































































































import Vue from "vue";
import {
  UserConfigurationType,
  DistanceReportSearchCriteria,
  DistanceReportContainerDto,
  RepositoryDto,
  CargoType,
  ActorType
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import SwitchSingle from "@/components/shared/input/SwitchSingle.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import money, { percentage } from "@/scripts/misc/money";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    ReadOnlyText,
    SwitchSingle
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: DistanceReportContainerDto
  },
  computed: {
    searchCriteria(): DistanceReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DistanceReport,
        1
      );
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes().filter(c =>
        [CargoType.Road, CargoType.MachineTransport].includes(c.id)
      );
    },
    percentWithDistance(): string {
      if (!this.reportContainer.numLoads) {
        return "";
      }
      return `(${percentage(
        this.reportContainer.numLoadsWithDistance /
          this.reportContainer.numLoads,
        0
      )})`;
    },
    percentWithFT(): string {
      if (!this.reportContainer.numLoads) {
        return "";
      }
      return `(${percentage(
        this.reportContainer.numLoadsFT / this.reportContainer.numLoads,
        0
      )})`;
    },
    percentDistanceWithFT(): string {
      if (!this.reportContainer.totalDistance) {
        return "";
      }
      return `(${percentage(
        this.reportContainer.distanceFT / this.reportContainer.totalDistance,
        0
      )})`;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    }
  },
  methods: {
    money: money,
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
