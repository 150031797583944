






















































































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { activeInactiveItems, userLevels } from "@/scripts/misc/enumLists";
import { usernameErrors, validEmail } from "@/scripts/misc/stringUtils";
import {
  ApiConfiguration,
  BasicUserSettingsDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { pushNotification } from "@/scripts/misc/notifications";
import { userLevelName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    TextSingle,
    Dropdown,
    ReadOnlyText
  },
  props: {
    basicUserSettings: BasicUserSettingsDto,
    pendingChanges: Boolean
  },
  watch: {
    "basicUserSettings.username"() {
      if (this.basicUserSettings.loadUserId === 0) {
        this.checkUsername();
      }
    }
  },
  computed: {
    loadUserId(): number {
      return this.$store.getters[gets.userInfo].loadUserId;
    }
  },
  methods: {
    validEmail: validEmail,
    userLevels: userLevels,
    userLevelName: userLevelName,
    activeInactiveItems: activeInactiveItems,
    resetCredentials() {
      this.resetCredentialsInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .resetCredentials(this.basicUserSettings.username!)
        .then(() => {
          pushNotification(t("AnEmailHasBeenSent"), "mdi-email");
          this.resetCredentialsInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    checkUsername() {
      const usernameBeingChecked = this.basicUserSettings.username;
      const errors = usernameErrors(usernameBeingChecked);
      if (errors) {
        this.usernameError = errors;
        return;
      }
      this.checkingUsername = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .checkUsername(usernameBeingChecked!)
        .then(taken => {
          if (this.basicUserSettings.username === usernameBeingChecked) {
            this.usernameError = taken ? t("UsernameNotAvailable") : undefined;
            this.checkingUsername = false;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    resetCredentialsInProgress: boolean;
    usernameError: string | undefined;
    checkingUsername: boolean;
  } => ({
    resetCredentialsInProgress: false,
    usernameError: undefined,
    checkingUsername: false
  })
});
