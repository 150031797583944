












































import Vue from "vue";
import {
  ApiConfiguration,
  StandardPhrasesClient,
  AgreementRouteStandardPhraseDto,
  StandardPhraseType
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import StandardPhrasesTable from "@/components/web/standardphrases/StandardPhrasesTable.vue";
import StandardPhrasesDialog from "@/components/web/standardphrases/StandardPhrasesDialog.vue";
import MomentX from "@/scripts/misc/momentX";
import { t } from "@/scripts/language/i18n";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  components: {
    SearchCard,
    StandardPhrasesDialog,
    StandardPhrasesTable
  },
  methods: {
    fetchStandardPhrases() {
      this.loading = true;
      new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .listAgreementRouteStandardPhrases()
        .then(res => {
          this.standardPhrases = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    openDialog(id: number) {
      this.selectedStandardPhrase = this.standardPhrases.find(
        phrase => phrase.id === id
      );
    },
    closeStandardPhraseDialog() {
      this.selectedStandardPhrase = undefined;
      this.fetchStandardPhrases();
    },
    filterPhrasesByType(type: number) {
      return this.standardPhrases.filter(phrase => phrase.phraseType === type);
    },
    createNewStandardPhrase() {
      this.selectedStandardPhrase = new AgreementRouteStandardPhraseDto({
        id: 0,
        subject: "",
        body: "",
        phraseType: this.tabItems[this.selectedTab].phraseType,
        userDisplayName: "",
        regDate: new MomentX(),
        agreementRoutesDeliveryInfos: [],
        agreementRoutesPickupInfos: [],
        agreementRoutesSpecialRequirements: [],
        pickupInfoCargoTypes: [],
        destinationInfoCargoTypes: [],
        specialRequirementCargoTypes: []
      });
    },
    deleteStandardPhrase(id: number) {
      popupDialog({
        title: t("Delete"),
        body: t("ConfirmRemoveText"),
        btnText1: t("Survey_Yes"),
        btnColor1: "error",
        btnCallback1: () => {
          this.actuallyDeleteStandardPhrase(id);
        },
        btnText2: t("Cancel"),
        btnCallback2: () => {}
      });
    },
    actuallyDeleteStandardPhrase(id: number) {
      new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .deleteAgreementRouteStandardPhrase(id)
        .then(() => this.fetchStandardPhrases())
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchStandardPhrases();
  },
  data: (): {
    loading: boolean;
    standardPhrases: AgreementRouteStandardPhraseDto[];
    selectedStandardPhrase: AgreementRouteStandardPhraseDto | undefined;
    selectedTab: number;
    tabItems: {
      text: string;
      phraseType: StandardPhraseType;
      icon: string;
      color?: string;
    }[];
  } => ({
    loading: false,
    standardPhrases: [],
    selectedStandardPhrase: undefined,
    selectedTab: 0,
    tabItems: [
      {
        text: t("PickupInformation"),
        phraseType: StandardPhraseType.PickupInfo,
        icon: "mdi-tray-arrow-up"
      },
      {
        text: t("DestinationInformation"),
        phraseType: StandardPhraseType.DestinationInfo,
        icon: "mdi-tray-arrow-down"
      },
      {
        text: t("SpecialRequirements"),
        phraseType: StandardPhraseType.SpecialRequirement,
        icon: "mdi-exclamation-thick",
        color: "error"
      }
    ]
  })
});
