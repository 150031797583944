



























































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import AdministrateUserDialog from "@/components/web/settings/AdministrateUserDialog.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import {
  ApiConfiguration,
  UsersClient,
  BasicUserSettingsDto,
  UserLevel,
  RepositoryDto
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions, gets } from "@/scripts/store/constants";
import { TableHeader } from "@/scripts/types";
import { userLevelName } from "@/scripts/misc/enumNames";
import { excelPrompt } from "@/scripts/misc/filePrompts";

export default Vue.extend({
  components: {
    SearchCard,
    CustomTableHeader,
    AdministrateUserDialog
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 175);
    },
    filteredUsers(): BasicUserSettingsDto[] {
      return this.users.filter(u => this.showInactivated || u.enabled);
    },
    loadUsersForSettingsImport(): RepositoryDto[] {
      return this.users.map(
        u => new RepositoryDto({ id: u.loadUserId, text: u.username })
      );
    }
  },
  methods: {
    userLevelName: userLevelName,
    close() {
      this.selectedSettings = undefined;
      this.search();
    },
    reopen() {
      this.selectedSettings = undefined;
      this.search().then(() => {
        this.selectedSettings = this.users.reduce((max, user) =>
          max.loadUserId > user.loadUserId ? max : user
        );
      });
    },
    createUser() {
      this.selectedSettings = new BasicUserSettingsDto({
        loadUserId: 0,
        username: undefined,
        name: undefined,
        email: undefined,
        mobileNo: undefined,
        userLevel: UserLevel.User,
        enabled: true
      });
    },
    async search() {
      this.loading = true;
      return new UsersClient(new ApiConfiguration(this.$store))
        .basicUserSettingsList()
        .then(res => {
          this.users = res;
          this.loading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    exportToExcel() {
      this.exporting = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .userListExport()
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: boolean;
    showInactivated: boolean;
    exporting: boolean;
    selectedSettings: BasicUserSettingsDto | undefined;
    users: BasicUserSettingsDto[];
    headers: TableHeader[];
  } => ({
    loading: true,
    showInactivated: false,
    exporting: false,
    selectedSettings: undefined,
    users: [],
    headers: [
      {
        value: "action",
        text: "",
        width: 50,
        untranslated: true
      },
      {
        value: "enabled",
        text: "Status",
        sortValue: true
      },
      {
        value: "name",
        text: "Name",
        sortValue: true
      },
      {
        value: "username",
        text: "UserName",
        sortValue: true
      },
      {
        value: "email",
        text: "Email",
        sortValue: true
      },
      {
        value: "userLevel",
        text: "UserLevel",
        sortValue: true
      }
    ]
  })
});
